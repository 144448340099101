<template>
  <div class="order-confirm-conainer">
    <div class="gift-goods-container">
      <div class="goods-title">{{ goodsInfo.title }}</div>
      <div class="goods-info">
        <div class="cover">
          <img :src="goodsInfo.cover">
        </div>
        <div class="info-container">
          <div class="container">
            礼包内包含 {{ goodsList.length }} 个店铺的超值商品
          </div>
          <div class="goods-price">
            售价：¥ {{ goodsInfo.market_price }}
          </div>
          <div class="goods-price">
            X {{ goodsInfo.goods_number }}
          </div>
        </div>
      </div>
    </div>

    <div class="stores-goods-info">
      <div :class="[isHide ? 'goods-list-limit' : '']" class="goods-list-container">
        <div class="goods-list" v-for="(item,index) in goodsList" :key="index">
          <div class="store-info">
            <div class="store-logo">
              <img :src="require('@STATIC/image/icons/shop.png')"/>
            </div>
            <div class="store-name">{{ item.store_name }}</div>
          </div>
          <div class="goods" v-for="(goods,goodsIndex) in item.goods_list" :key="goodsIndex">
            <div class="goods-cover">
              <img :src="goods.cover">
            </div>
            <div class="goods-info">
              <div class="goods-info-title">{{ goods.title }}</div>
              <div class="goods-d-market-price">可核销 {{ goods.verification_times }} 次</div>
            </div>
          </div>
        </div>
      </div>
      <div class="view-more" @click="isHide = !isHide">
        <img v-if="!isHide" :src="require('@STATIC/image/icons/arrowUp.png')"/>
        <img v-if="isHide" :src="require('@STATIC/image/icons/arrowDown.png')"/>
      </div>
    </div>

    <div class="order-info">
      <div class="order-info-line">
        <div class="order-info-line-left">使用方式</div>
        <div class="order-info-line-right">
          由联盟店铺核销
        </div>
      </div>
      <div class="order-info-line">
        <div class="order-info-line-left">商品金额</div>
        <div class="order-info-line-right">
          ¥{{ orderInfo.market_price }}
        </div>
      </div>
      <div class="order-info-line">
        <div class="order-info-line-left">使用截止时间</div>
        <div class="order-info-line-right">
          {{ orderInfo.expire_verify_time }}
        </div>
      </div>
      <div class="order-info-line2">
        <div class="order-info-line2-left">请选择支付方式</div>
        <div class="order-info-line2-right">
          <div class="payment-type">
            <div class="payment-type-item" style="margin-bottom: 10px">
              <checkedV1 :value="pay_type == 'wechat'" @change="chooseWechat">
                <span class="payment-type-item-span">微信支付</span>
              </checkedV1>
            </div>
            <div class="payment-type-item">
              <checkedV1 :value="pay_type == 'money'" @change="chooseMoney">
                <span class="payment-type-item-span">余额支付</span>
              </checkedV1>
            </div>
            <div class="payment-type-text" v-if="pay_type == 'money'">
              可用余额：¥ {{ userMoney }}
            </div>
          </div>
        </div>
      </div>

      <div class="footbarV2">
        <div>
          <div>总计: ¥{{ orderInfo.market_price }}</div>
        </div>
        <button @click="goBuy">去结算</button>
      </div>
    </div>
  </div>
</template>

<script>
import checkedV1 from "@T/community/checked/checkedV1"

export default {
  name: 'join-order-confirm',
  data() {
    return {
      goodsId: 0,
      goodsNumber: 1,
      goodsInfo: {},
      goodsList: [],
      orderInfo: {},
      isHide: false,
      pay_type: 'wechat',
      userMoney: 0
    }
  },
  components: {
    checkedV1
  },
  mounted() {
    let route = this.$route
    console.log('route', route)
    this.goodsId = route.query.goods_id
    this.goodsNumber = route.query.num
    this.getJoinOrderView()
  },
  methods: {
    getJoinOrderView() {
      let params = {
        goods_id: this.goodsId,
        goods_number: this.goodsNumber
      }
      this.$api.community.storeJoins.orderView(params).then(response => {
        this.goodsInfo = response.data.goods
        this.goodsList = response.data.goods_list
        this.orderInfo = response.data.order_info
      })
    },
    chooseWechat() {
      this.pay_type = 'wechat'
    },
    chooseMoney() {
      this.pay_type = 'money'
      this.getUserMoney()
    },
    getUserMoney() {
      this.$api.community.users.getUserMoney().then(response => {
        this.userMoney = response.data
      })
    },
    goBuy() {
      let self = this
      let params = {
        goods_id: this.goodsId,
        goods_number: this.goodsNumber,
        pay_type: this.pay_type
      }
      this.$api.community.storeJoins.createStoreJoinOrder(params).then(response => {
        if (params.pay_type == 'money') {
          this.$dialog({
            title: '温馨提示',
            content: response.message,
            closeOnClickModal: false,
            closeBtn: false,
            onOkBtn() {
              self.$dialog.closed()
              self.goToOrderList()
            },
            onCancelBtn() {
              this.$dialog.closed()
            }
          })
        }else{
          this.wxpay(response.data)
        }
      })
    },
    wxpay (payInfo) {
      this.paying = false
      let options = encodeURIComponent(JSON.stringify(payInfo))
      this.$platform.wxsdk.wxRoute({
        type: 'redirectTo',
        // type: "navigateTo",
        url: '/web/money/join-order-pay?query=' + options,
      })
    },
    goToOrderList(){
      this.$platform.wxsdk.wxRoute({
        type: 'redirectTo',
        url: '/order/list/common',
      })
    },
  }
}
</script>

<style scoped lang="scss">
.order-confirm-conainer {
  width: 100vw;
  min-height: 100vh;
  background: #f6f6f6;
  display: flex;
  flex-direction: column;

  .gift-goods-container {
    display: flex;
    flex-direction: column;
    background: #fff;
    padding: 10px 15px;
    border-radius: 8px;
    margin-top: 10px;

    .goods-title {
      flex: 1;
      font-size: 16px;
      font-weight: 400;
    }

    .goods-info {
      flex: 1;
      display: flex;
      flex-direction: row;
      margin-top: 10px;

      .cover {
        width: 80px;
        height: 80px;
        border-radius: 10px;
        overflow: hidden;

        img {
          width: 80px;
          height: 80px;
        }
      }

      .info-container {
        flex: 1;
        display: flex;
        flex-direction: column;
        margin-left: 10px;

        .container, .goods-price {
          font-size: 14px;
          flex: 1;
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }

  .stores-goods-info {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    background: #fff;
    border-radius: 10px;

    .goods-list-container {
      .goods-list {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding: 10px 15px;

        .store-info {
          flex: 1;
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-bottom: 5px;

          .store-logo {
            width: 19px;
            height: 19px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 4px;

            img {
              width: 19px;
              height: 19px;
            }
          }

          .store-name {
            font-size: 14px;
          }
        }

        .goods {
          display: flex;
          flex-direction: row;
          margin-bottom: 10px;

          .goods-cover {
            width: 80px;
            height: 80px;
            border-radius: 10px;
            overflow: hidden;
            display: flex;


            img {
              width: 80px;
              height: 80px;
            }
          }

          .goods-info {
            flex: 1;
            flex-direction: column;

            .goods-info-title {
              font-size: 14px;
              display: flex;
              justify-content: flex-end;
              margin-bottom: 10px;
            }

            .goods-market-price, .goods-d-market-price {
              display: flex;
              justify-content: flex-end;
              font-size: 12px;
            }
          }
        }
      }
    }

    .goods-list-limit {
      height: 140px;
      overflow: hidden;
    }

    .view-more {
      width: 20%;
      margin-left: 40%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(0, 0, 0, 0.1);
      margin-bottom: 5px;
      border-radius: 5px;
      padding: 3px 0;

      img {
        width: 16px;
        height: 16px;
      }
    }
  }

  .order-info {
    display: flex;
    flex-direction: column;
    background: #fff;
    padding: 10px 15px;
    margin-top: 10px;
    margin-bottom: 100px;

    .order-info-line {
      border-bottom: #ebebeb solid 1px;
      display: flex;
      flex-direction: row;
      padding: 10px 0;

      .order-info-line-left {
        width: 100px;
        font-size: 14px;
      }

      .order-info-line-right {
        flex: 1;
        font-size: 14px;
        display: flex;
        justify-content: flex-end;
      }
    }

    .order-info-line2 {
      display: flex;
      flex-direction: row;
      padding: 10px 0;
      margin-top: 10px;

      .order-info-line2-left {
        width: 100px;
        font-size: 14px;
      }

      .order-info-line2-right {
        flex: 1;
        font-size: 14px;
        display: flex;
        justify-content: flex-end;

        .payment-type {
          display: flex;
          flex-direction: column;
          margin-top: 20px;

          .payment-type-item {
            flex: 1;
            display: flex;
            justify-content: flex-end;
            font-size: 12px;

            .payment-type-item-span {
              margin-left: 5px;
              margin-top: 1px;
            }
          }

          .payment-type-text {
            flex: 1;
            display: flex;
            justify-content: flex-end;
            font-size: 10px;
            margin-top: 3px;
          }
        }
      }
    }
  }
}

@include b(footbarV2) {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 375px;
  height: 56px;
  background: #ffffff;
  box-shadow: 0px 0px 0px 0px rgba(209, 209, 209, 1);
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > div {
    box-sizing: border-box;
    padding: 11px 0 11px 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    & > div {
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;

      &:last-of-type {
        display: flex;
        align-items: center;

        & > span {
          font-size: 10px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #eb6100;

          &:last-of-type {
            margin-left: 9px;
            background: #ebebeb;
            border-radius: 8px;
            box-sizing: border-box;
            padding: 3px 6px;
            color: #939393;

            &:active {
              opacity: 0.5;
            }
          }
        }
      }
    }
  }
  & > button {
    margin-right: 14px;
    width: 115px;
    height: 36px;
    background: linear-gradient(139deg, #fac484 0%, #f46a17 100%);
    border-radius: 18px;
    border: none;
    outline: none;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;

    &:active {
      opacity: 0.5;
    }
  }
}
</style>
